import React from 'react';
import { Helmet } from 'react-helmet';

import DefaultLayout from '../layouts';
import ToggleBox from '../components/toggle-box';
import Metadata from '../components/metadata';

import * as styles from './2019-swing-of-time.module.css';

import playBadge from '../assets/google-play-badge.png';
import titleImg from '../assets/2019/title.svg';
import carImg from '../assets/2019/car.svg';
import signImg from '../assets/2019/sign.svg';

import etasku from '../assets/common/ads/etasku.png';
import etteplan from '../assets/2019/ads/etteplan.svg';
import glitternisti from '../assets/2019/ads/glitternisti.svg';
import hene from '../assets/2019/ads/hene.png';
import juvenes from '../assets/2019/ads/juvenes.png';
import missWindyShop from '../assets/2019/ads/miss_windy_shop.svg';
import poas from '../assets/2019/ads/poas.svg';
import poppamies from '../assets/2019/ads/poppamies.svg';
import punanaamio from '../assets/2019/ads/punanaamio.png';
import ratekoulutus from '../assets/2019/ads/ratekoulutus.png';
import riemurinne from '../assets/common/ads/riemurinne_netti.png';
import sodexo from '../assets/2019/ads/sodexo.svg';
import sori from '../assets/2019/ads/sori.svg';
import tamppi from '../assets/common/ads/tamppi.png';
import tek from '../assets/common/ads/tek.svg';
import toas from '../assets/2019/ads/toas.svg';
import tuni from '../assets/2019/ads/tuni.svg';
import fablab from '../assets/2019/ads/fablab.png';

const Raina2019 = ({ location }) => (
  <DefaultLayout>
    <Helmet htmlAttributes={{ class: styles.root2019 }} />

    <Metadata
      page={{
        title: 'Swing of Time – 50-luvun viemää',
        excerpt:
          'Vuoden 2019 NääsPeksi “Swing of Time – 50-luvun viemää” kutsuu nimensä mukaisesti katsojansa värikkään 50-luvun pauloihin ja pyörteisiin. Waspin mahtisuku on kietonut Springfieldin idyllisen pikkukaupungin verkkoonsa. Häikäilemättömän Daniel Waspin suunnitelmat mutkistuvat, kun kaupunkiin saapuu omalaatuinen muukalainen Tony Valentine. Kun Waspin voimakastahtoinen kihlattu Emma Baker iskee tulokkaaseen silmänsä, kolmiodraama on valmis.',
      }}
      location={location}
    />

    <div className={styles.contentWrapper}>
      <div className={styles.dinerMenu}>
        <header>
          <img
            src={titleImg}
            alt="Swing of Time"
            title="Swing of Time"
            className={styles.titleImg}
            height="17rem"
          />
        </header>

        <div className={styles.grid}>
          <div className={styles.column}>
            <h2>Synopsis</h2>
            <div className={styles.columnInner}>
              <p>
                <strong>“Perjantai 26. kesäkuuta... 1959!?”</strong>
              </p>
              <p>
                Vuoden 2019 NääsPeksi “
                <strong>Swing of Time – 50-luvun viemää</strong>” kutsuu nimensä
                mukaisesti katsojansa värikkään 50-luvun pauloihin ja
                pyörteisiin. Waspin mahtisuku on kietonut Springfieldin
                idyllisen pikkukaupungin verkkoonsa. Häikäilemättömän Daniel
                Waspin suunnitelmat mutkistuvat, kun kaupunkiin saapuu
                omalaatuinen muukalainen Tony Valentine. Kun Waspin
                voimakastahtoinen kihlattu Emma Baker iskee tulokkaaseen
                silmänsä, kolmiodraama on valmis.
              </p>
              <p>
                Muuttaako Waspin suvun uusi yksityissairaala historian kulun
                pysyvästi? Syyttääkö pormestari Taylor Johnson kommunisteja?
                Kuka saa ja kenet? Ja mikä on automekaanikko Eddie O’Reallyn
                erityistaito?
              </p>
              <p>
                NääsPeksin 20. juhlavuosi tarjoaa vauhtia, vaarallisia
                tilanteita ja suuria tunteita. Älä takerru hetkeen vaan osta
                liput syksyä piristävään spektaakkeliin ja anna 50-luvun viedä!
              </p>
            </div>
          </div>

          <div className={styles.column}>
            <h2>Esitykset</h2>
            <ul className={styles.eventList}>
              <li>
                <div className={styles.eventListTitle}>
                  <span>Tampere-talo</span>
                  <div className={styles.eventListSpacer}></div>
                  <span>12.11.</span>
                </div>
                <div className={styles.eventStoreLinks}>
                  <a
                    href="https://naaspeksi.fi/tapahtumat/12_11-2019-1800/"
                    target="_blank"
                  >
                    NääsPeksi-kauppa »
                  </a>
                </div>
              </li>
              <li>
                <div className={styles.eventListTitle}>
                  <span>Turku, Logomo</span>
                  <div className={styles.eventListSpacer}></div>
                  <span>18.11.</span>
                </div>
                <div className={styles.eventStoreLinks}>
                  <a
                    href="https://www.lippu.fi/event/naeaespeksi-2019-swing-of-time-50-luvun-viemaeae-logomo-12466674/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lippu.fi »
                  </a>
                </div>
              </li>
              <li>
                <div className={styles.eventListTitle}>
                  <span>Pakkahuone I</span>
                  <div className={styles.eventListSpacer}></div>
                  <span>21.11.</span>
                </div>
                <div className={styles.eventStoreLinks}>
                  <a
                    href="https://www.tiketti.fi/tapahtuma/65012"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tiketti »
                  </a>
                  <a
                    href="https://www.lippu.fi/naaspeksipakkahuone"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lippu.fi »
                  </a>
                </div>
              </li>
              <li>
                <div className={styles.eventListTitle}>
                  <span>Oulu, Pohjankartano</span>
                  <div className={styles.eventListSpacer}></div>
                  <span>23.11.</span>
                </div>
                <div className={styles.eventStoreLinks}>
                  <a
                    href="https://naaspeksi.fi/uutiset/naaspeksin-oulun-naytoksen-lipunmyynti/"
                    target="_blank"
                  >
                    Tietoa lipunmyynnistä »
                  </a>
                </div>
              </li>
              <li>
                <div className={styles.eventListTitle}>
                  <span>Pakkahuone II</span>
                  <div className={styles.eventListSpacer}></div>
                  <span>27.11.</span>
                </div>
                <div className={styles.eventStoreLinks}>
                  <a
                    href="https://www.tiketti.fi/tapahtuma/65012"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tiketti »
                  </a>
                  <a
                    href="https://www.lippu.fi/naaspeksipakkahuone"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lippu.fi »
                  </a>
                </div>
              </li>
              <li>
                <div className={styles.eventListTitle}>
                  <span>Pakkahuone III</span>
                  <div className={styles.eventListSpacer}></div>
                  <span>28.11.</span>
                </div>
                <div className={styles.eventStoreLinks}>
                  <a
                    href="https://www.tiketti.fi/tapahtuma/65012"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tiketti »
                  </a>
                  <a
                    href="https://www.lippu.fi/naaspeksipakkahuone"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lippu.fi »
                  </a>
                </div>
              </li>
              <li>
                <div className={styles.eventListTitle}>
                  <span>Helsinki, Teatteri Forum</span>
                  <div className={styles.eventListSpacer}></div>
                  <span>3.12.</span>
                </div>
                <div className={styles.eventStoreLinks}>
                  <a
                    href="https://naaspeksi.fi/tapahtumat/03_12-2019-1800/"
                    target="_blank"
                  >
                    NääsPeksi-kauppa »
                  </a>
                </div>
              </li>
            </ul>

            <div className={styles.columnInner}>
              <p>
                Pääsylippu maksaa työssäkäyville 22 € ja muille (opiskelijat,
                työttömät, eläkeläiset, varusmiehet, tms.) 15&nbsp;€. Lisäksi
                tarjolla on 30&nbsp;€ paikkalippu, jolla varaamme asiakkaalle
                salista nimetyn paikan ja painetun käsiohjelman. Teatteri
                Forumin narikkamaksu on 3,5&nbsp;€.
              </p>

              <p>
                Kaikki näytökset alkavat klo 18:00 ja kestävät väliajan kanssa
                noin kolme tuntia. Ovet aukeavat noin 30 minuuttia ennen
                esitysten alkua. Paikkavarauksia ja Turun esitystä lukuun
                ottamatta salien istumapaikat täytetään vapaasti
                saapumisjärjestyksessä.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.gameAd}>
          <div className={styles.gameAdContent}>
            <h2>Pelaa ja voita!</h2>
            <p>
              NääsPeksi on julkaissut 20. juhlavuoden kunniaksi mobiilipelin!
              Haluatko juuri sinä haluat voittaa liput syksyn näytöksiin?
              Palkitsemme joka viikko parhaan pelaajan, joten pistä itsesi
              likoon ja lunasta paikkasi nostalgiseen esitykseen Swing of Time!
            </p>
            <p>
              <a
                href="https://play.google.com/store/apps/details?id=com.TopSecret.Kadillak"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={playBadge} alt="" style={{ width: '8rem' }} />
              </a>
            </p>
          </div>
          <img
            src={carImg}
            alt=""
            className={styles.gameCar}
            style={{ maxWidth: '25rem' }}
          />
        </div>

        <div className={styles.contentPadding}>
          <h2>Tekijät</h2>

          <div className={styles.toggleBoxGrid}>
            <div className={styles.columnInner}>
              <ToggleBox
                title="Ohjaaja"
                defaultOpen={true}
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>Järvinen Janne</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Tuottajat"
                defaultOpen={true}
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>Erola Erik</li>
                  <li>Immonen Juuli</li>
                  <li>Koivuniemi Meiju</li>
                  <li>Salosyrjä Henna</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Bändi"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Etelätalo Olli</strong>
                  </li>
                  <li>
                    <strong>Leinonen Lauri</strong>
                  </li>
                  <li>Gullsten Niko</li>
                  <li>Hongisto Taneli</li>
                  <li>Kallioniemi Leevi</li>
                  <li>Mäkinen Tatu</li>
                  <li>Niemi Petteri</li>
                  <li>Paalanen Niilo</li>
                  <li>Sihvo Jussi</li>
                  <li>Sipilä Enni</li>
                  <li>Tammelin Terhi</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Hyvinvointi ja viihtyvyys"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Leppänen Jari</strong>
                  </li>
                  <li>Arnivaara Isla</li>
                  <li>Erola Erik</li>
                  <li>Grönholm Anniina</li>
                  <li>Kallio Sanna</li>
                  <li>Koivuniemi Meiju</li>
                  <li>Laitinen Alpo</li>
                  <li>Nortunen Hari</li>
                  <li>Peltohaka Pinja</li>
                  <li>Pullinen Anu</li>
                  <li>Rintala Janita</li>
                  <li>Urpiola Aino</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Käsikirjoitus"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Grönholm Anniina</strong>
                  </li>
                  <li>
                    <strong>Altsten Alfred</strong>
                  </li>
                  <li>Eronen Lydia</li>
                  <li>Hartikainen Enni</li>
                  <li>Kallio Sanna</li>
                  <li>Leppänen Jari</li>
                  <li>Mäkelä Severi</li>
                  <li>Mäntysaari Juho</li>
                  <li>Nikander Sonja</li>
                  <li>Niskanen Aku</li>
                  <li>Nortunen Hari</li>
                  <li>Parviainen Patrik</li>
                  <li>Pikkarainen Elina</li>
                  <li>Ratas Samuli</li>
                  <li>Roinisto Elisa</li>
                  <li>Säätelä Annukka</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Koreografit"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Laitinen Tanja</strong>
                  </li>
                  <li>Hakala Anni</li>
                  <li>Hietala Anniina</li>
                  <li>Kalliala Samuli</li>
                  <li>Koivuniemi Meiju</li>
                  <li>Lehtinen Marianne</li>
                  <li>Salo Lisa</li>
                  <li>Toivettula Reetta</li>
                </ul>
              </ToggleBox>
            </div>

            <div className={styles.columnInner}>
              <ToggleBox
                title="Laulunopettaja"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>Arponen Jenni</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Lavastus"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Kotajärvi Miika</strong>
                  </li>
                  <li>Alanko Ari</li>
                  <li>Erola Erik</li>
                  <li>Halonen Anniina</li>
                  <li>Harjuniemi Riina</li>
                  <li>Heikkinen Jussi</li>
                  <li>Huuskola Sakari</li>
                  <li>Hyvönen Reima</li>
                  <li>Jokelainen Juho</li>
                  <li>Kaukoranta Susanna</li>
                  <li>Kemppi Antti</li>
                  <li>Kiiski Henri</li>
                  <li>Kopra Anna</li>
                  <li>Kotajärvi Miika</li>
                  <li>Kujala Matti</li>
                  <li>Mäntysaari Juho</li>
                  <li>Martikainen Valtteri</li>
                  <li>Niskanen Aku</li>
                  <li>Nousiainen Ville</li>
                  <li>Pärssinen Miika</li>
                  <li>Rintala Janita</li>
                  <li>Suupohja Kiti</li>
                  <li>Virtanen Anni</li>
                  <li>Virtanen Maisa</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Lipunmyynti"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Pikkarainen Elina</strong>
                  </li>
                  <li>Arnivaara Isla</li>
                  <li>Laine Sanni</li>
                  <li>Laine Santtu</li>
                  <li>Nortunen Hari</li>
                  <li>Patrakka Jani</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Logistiikka"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Pitkäjärvi Lauri</strong>
                  </li>
                  <li>Alanko Ari</li>
                  <li>Erola Erik</li>
                  <li>Hyvönen Reima</li>
                  <li>Kujala Katja</li>
                  <li>Kuusisto Lauri</li>
                  <li>Laine Santtu</li>
                  <li>Laitinen Alpo</li>
                  <li>Niskanen Aku</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Mainosmyynti"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Patrakka Jani</strong>
                  </li>
                  <li>Kemppi Antti</li>
                  <li>Parviola Nina</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Markkinointi"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Jokinen Katariina</strong>
                  </li>
                  <li>Altsten Alfred</li>
                  <li>Laine Sanni</li>
                  <li>Nortunen Hari</li>
                  <li>Patrakka Jani</li>
                  <li>Pikkarainen Elina</li>
                  <li>Urpiola Aino</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Näyttämömestari"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>Niskanen Aku</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Näyttelijät"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>Altsten Alfred</li>
                  <li>Brown Emma</li>
                  <li>Haapakangas Elina</li>
                  <li>Hartikainen Enni</li>
                  <li>Hirvonen Otto-Harald</li>
                  <li>Jussilainen Rosa</li>
                  <li>Leinonen Peitsa</li>
                  <li>Leppänen Jari</li>
                  <li>Niskanen Aku</li>
                  <li>Salonen Roope</li>
                  <li>Saraniemi Miikka</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Puvustus"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Hansen-Haug Heidi</strong>
                  </li>
                  <li>
                    <strong>Hakkarainen Enni</strong>
                  </li>
                  <li>Kaski Erika</li>
                  <li>Laine Santtu</li>
                  <li>Lindberg Ronja</li>
                  <li>Luhtala Janina</li>
                  <li>Palmu Niina</li>
                  <li>Parviola Nina</li>
                  <li>Salo Lisa</li>
                  <li>Tuomela Heidi</li>
                  <li>Virtanen Maisa</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Ruokatiimi"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Färm Minna</strong>
                  </li>
                  <li>
                    <strong>Alanko Ari</strong>
                  </li>
                  <li>Juvonen Anu</li>
                  <li>Kaski Erika</li>
                  <li>Kaukoranta Susanna</li>
                  <li>Nikander Sonja</li>
                  <li>Niskanen Aku</li>
                  <li>Virtanen Anni</li>
                  <li>Virtanen Maisa</li>
                </ul>
              </ToggleBox>
            </div>

            <div className={styles.columnInner}>
              <ToggleBox
                title="Sanoitus"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Arponen Jenni</strong>
                  </li>
                  <li>Arnivaara Isla</li>
                  <li>Etelätalo Olli</li>
                  <li>Hakala Tuomas</li>
                  <li>Jussilainen Rosa</li>
                  <li>Karintaus Mikko</li>
                  <li>Korpisaari Kaisa</li>
                  <li>Meriläinen Ella</li>
                  <li>Patrakka Jani</li>
                  <li>Qvick Pyry</li>
                  <li>Saraniemi Miikka</li>
                  <li>Tuokkola Jarno</li>
                  <li>Vesamaa Mikko</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Taikatiimi (maski)"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Holmroos Marianna</strong>
                  </li>
                  <li>Kuusisto Jasmin</li>
                  <li>Lahtinen Ilona</li>
                  <li>Liede Selja</li>
                  <li>Luhtala Janina</li>
                  <li>Palmu Niina</li>
                  <li>Salonen Anni</li>
                  <li>Turunen Lotta</li>
                  <li>Urpiola Aino</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Taikatiimi (tukka)"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Kujala Katja</strong>
                  </li>
                  <li>Hakkarainen Enni</li>
                  <li>Kopra Anna</li>
                  <li>Liede Selja</li>
                  <li>Mäenhovi Milla</li>
                  <li>Rajala Minna</li>
                  <li>Tuomela Heidi</li>
                  <li>Urpiola Aino</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Taltiointi"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Hakojärvi Juha-Matti</strong>
                  </li>
                  <li>Eilo Aleksi</li>
                  <li>Hakala Atte</li>
                  <li>Heikkilä Jan</li>
                  <li>Jarva Tuisku</li>
                  <li>Kangas Teemu</li>
                  <li>Kiviluoma Mika</li>
                  <li>Kohonen Mikko</li>
                  <li>Lakaniemi Esa</li>
                  <li>Niskanen Aku</li>
                  <li>Tuokkola Jarno</li>
                  <li>Tuukkanen Aaro</li>
                  <li>Vilkko Marja</li>
                  <li>Vuolasaho Hannu</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Tanssijat"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Lehtinen Marianne</strong>
                  </li>
                  <li>Förster Nanna</li>
                  <li>Hakala Anni</li>
                  <li>Henriksson Jenna</li>
                  <li>Jokinen Tapio</li>
                  <li>Koivuniemi Meiju</li>
                  <li>Nieppola Elsa</li>
                  <li>Siponen Minttu</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Tarpeisto"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Kuusisto Lauri</strong>
                  </li>
                  <li>Alanko Ari</li>
                  <li>Bergmann Anni</li>
                  <li>Enäsuo Lasse</li>
                  <li>Erola Erik</li>
                  <li>Hakkarainen Enni</li>
                  <li>Halonen Anniina</li>
                  <li>Janhunen Kuura</li>
                  <li>Jarva Tuisku</li>
                  <li>Juvonen Anu</li>
                  <li>Kallio Sanna</li>
                  <li>Kaski Erika</li>
                  <li>Kemppi Antti</li>
                  <li>Kujala Matti</li>
                  <li>Laine Sanni</li>
                  <li>Lakaniemi Esa</li>
                  <li>Mäkelä Severi</li>
                  <li>Nikander Sonja</li>
                  <li>Nortunen Hari</li>
                  <li>Palmu Niina</li>
                  <li>Parviainen Patrik</li>
                  <li>Peltohaka Pinja</li>
                  <li>Pihlava Tuomas</li>
                  <li>Pitkäjärvi Lauri</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Tekniikka"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Khaikovitch Iivari</strong>
                  </li>
                  <li>
                    <strong>Pihlajaniemi Janne</strong>
                  </li>
                  <li>Heikkilä Jan</li>
                  <li>Kallunki Antti</li>
                  <li>Kohonen Mikko</li>
                  <li>Korolainen Aino</li>
                  <li>Lahti Joonas</li>
                  <li>Mäkinen Juhani</li>
                  <li>Rinta-Filppula Jaakko</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Velhot"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Kiiski Henri</strong>
                  </li>
                  <li>Jokelainen Juho</li>
                  <li>Lakaniemi Esa</li>
                  <li>Nousiainen Ville</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="Visualistit"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>
                    <strong>Suominen Suvi</strong>
                  </li>
                  <li>
                    <strong>Lähteenmäki Jere</strong>
                  </li>
                  <li>Färm Minna</li>
                  <li>Immonen Juuli</li>
                  <li>Korpisaari Kaisa</li>
                  <li>Rinta-Filppula Jaakko</li>
                  <li>Roinisto Elisa</li>
                  <li>Terho Sakari</li>
                </ul>
              </ToggleBox>

              <ToggleBox
                title="WWW"
                titleClassName={styles.toggleTitle}
                className={styles.toggleBox}
              >
                <ul>
                  <li>Rinta-Filppula Jaakko</li>
                </ul>
              </ToggleBox>
            </div>
          </div>
        </div>

        <div className={styles.contentPadding}>
          <h2>Yhteistyössä</h2>
          <div className={styles.adsList}>
            <a
              href="https://www.etasku.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={etasku} alt="eTasku" title="eTasku" />
            </a>

            <a
              href="https://www.etteplanmore.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={etteplan}
                alt="MORE by Etteplan"
                title="MORE by Etteplan"
              />
            </a>

            <a
              href="https://sites.tuni.fi/fablabtampere/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={fablab} alt="FabLab Tampere" title="FabLab Tampere" />
            </a>

            <a
              href="https://www.glitternisti.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={glitternisti} alt="Glitternisti" title="Glitternisti" />
            </a>

            <a
              href="https://hene.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={hene} alt="Hene Oy" title="Hene Oy" />
            </a>

            <a
              href="https://www.juvenes.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={juvenes} alt="Juvenes" title="Juvenes" />
            </a>

            <a
              href="https://www.misswindyshop.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={missWindyShop}
                alt="Miss Windy Shop"
                title="Miss Windy Shop"
              />
            </a>

            <a
              href="https://poas.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={poas} alt="POAS" title="POAS" />
            </a>

            <a
              href="https://www.poppamies.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={poppamies} alt="Poppamies" title="Poppamies" />
            </a>

            <a
              href="https://www.punanaamio.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={punanaamio} alt="Punanaamio" title="Punanaamio" />
            </a>

            <a
              href="https://www.ratekoulutus.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ratekoulutus} alt="Ratekoulutus" title="Ratekoulutus" />
            </a>

            <a
              href="http://www.riemurinne.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={riemurinne} alt="Riemurinne" title="Riemurinne" />
            </a>

            <a
              href="https://www.sodexo.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sodexo} alt="Sodexo" title="Sodexo" />
            </a>

            <a
              href="https://soribrewing.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sori} alt="Sori Brewing" title="Sori Brewing" />
            </a>

            <a
              href="https://www.tuni.fi/fi/palvelut-ja-yhteistyo/alumnille"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={tuni}
                alt="Tampereen yliopisto"
                title="Tampereen yliopisto"
              />
            </a>

            <a
              href="https://tamppi.fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tamppi} alt="Tamppi" title="Tamppi" />
            </a>

            <a
              href="https://www.tek.fi/fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tek} alt="TEK" title="TEK" />
            </a>

            <a
              href="https://toas.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={toas} alt="TOAS" title="TOAS" />
            </a>
          </div>
        </div>

        <img src={signImg} className={styles.sign} alt="Marilyn's diner" />
      </div>
    </div>
  </DefaultLayout>
);

export default Raina2019;
